<template src="./AdminNetworking.html">

</template>

<script>
import networkingService from "@/services/Networking";
import AdminConfirmationModal from './../AdminConfirmationModal/AdminConfirmationModal.vue'

export default {
  name: 'admin-networking',
  components: { AdminConfirmationModal },
  data () {
    return {
      aforo: null,
      rooms: null,
      idNetworking: null,
      roomsList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      aforoList: [10, 50, 100, 150, 200],
      openModal: false,
    }
  },
  async created() {
    try {
      const { id, aforo, rooms} = await networkingService.getNetworkingsAdmin();
      this.idNetworking = id;
      this.aforo = aforo;
      this.rooms = rooms;
    } catch (error) {
      console.error(error);
      this.$buefy.toast.open({
        duration: 5000,
        message: error.response.data.message,
        position: 'is-bottom',
        type: 'is-danger'
      });
    }
  },
  methods: {
    async patchRoomsAndAforo() {
      const obj = {
        id: this.idNetworking,
        aforo: parseInt(this.aforo),
        rooms: parseInt(this.rooms)
      }
      await networkingService.changeNetworkingsAdmin(obj);
      this.closeModal();
    },
    openAdminModal() {
      this.openModal = true
    },
    closeModal() {
      this.openModal = false
    },
  },
}
</script>

<style lang="scss" scoped src="./AdminNetworking.scss">

</style>
